// Generated by Framer (575e68f)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["E_a1dgoAd"];

const serializationHash = "framer-us78H"

const variantClassNames = {E_a1dgoAd: "framer-v-19d6naa"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "E_a1dgoAd", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", intrinsicHeight: 618, intrinsicWidth: 1048, pixelHeight: 1236, pixelWidth: 2096, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/Cwn6XnE4jekFztZ4TLa9YWAWNa0.png?scale-down-to=2048", srcSet: "https://framerusercontent.com/images/Cwn6XnE4jekFztZ4TLa9YWAWNa0.png?scale-down-to=512 512w,https://framerusercontent.com/images/Cwn6XnE4jekFztZ4TLa9YWAWNa0.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/Cwn6XnE4jekFztZ4TLa9YWAWNa0.png?scale-down-to=2048 2048w,https://framerusercontent.com/images/Cwn6XnE4jekFztZ4TLa9YWAWNa0.png 2096w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-19d6naa", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"E_a1dgoAd"} ref={ref ?? ref1} style={{"--border-bottom-width": "6px", "--border-color": "rgb(34, 34, 34)", "--border-left-width": "6px", "--border-right-width": "6px", "--border-style": "solid", "--border-top-width": "6px", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-us78H.framer-gmf4jm, .framer-us78H .framer-gmf4jm { display: block; }", ".framer-us78H.framer-19d6naa { height: 462px; overflow: visible; position: relative; width: 532px; }", ".framer-us78H[data-border=\"true\"]::after, .framer-us78H [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 462
 * @framerIntrinsicWidth 532
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerL1EpoPF7E: React.ComponentType<Props> = withCSS(Component, css, "framer-us78H") as typeof Component;
export default FramerL1EpoPF7E;

FramerL1EpoPF7E.displayName = "Windows Mockup";

FramerL1EpoPF7E.defaultProps = {height: 462, width: 532};

addFonts(FramerL1EpoPF7E, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})